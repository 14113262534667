<template>
    <div v-if="showAd" class="ad-container">
        <ins
            class="adsbygoogle"
            :style="{ display: 'block' }"
            :data-ad-client="adClient"
            :data-ad-slot="adSlot"
            :data-ad-format="format"
            :data-full-width-responsive="responsive"
        />
    </div>
    <div v-else class="ad-placeholder">
        <div class="dev-ad-notice">Emplacement publicité</div>
    </div>
</template>

<script setup>
const props = defineProps({
    adClient: {
        type: String,
        required: true,
    },
    adSlot: {
        type: String,
        required: true,
    },
    format: {
        type: String,
        default: "auto",
    },
    responsive: {
        type: Boolean,
        default: true,
    },
});

const showAd = process.env.NODE_ENV === "production";

onMounted(() => {
    if (showAd) {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (error) {
            console.error("Erreur lors du chargement de l'annonce:", error);
        }
    }
});
</script>

<style scoped>
.ad-container {
    text-align: center;
    margin: 1rem 0;
    min-height: 100px;
}

.dev-ad-notice {
    background: #f0f0f0;
    border: 2px dashed #ccc;
    padding: 1rem;
    text-align: center;
    color: #666;
}
</style>
