<template>
    <div
        class="w-full h-full flex flex-col items-center justify-center text-center"
    >
        <p class="text-md">{{ $t("best-score") }}</p>
        <p class="text-4xl md:text-6xl">{{ bestScore }}</p>
    </div>
</template>

<script setup lang="ts">
import { Games } from "@/utils/dicts";
import { useUserStatisticsStore } from "~/store/userStatistics";

const { userStatistics } = storeToRefs(useUserStatisticsStore());

const bestScore = computed(() => {
    if (!userStatistics.value[Games.Age]) return 0;

    return userStatistics.value[Games.Age].sort(
        (a: { score: number }, b: { score: number }) => b.score - a.score
    )[0].score;
});
</script>
