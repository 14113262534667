export default defineAppConfig({
    ui: {
        primary: "violet",
        gray: "cool",
        notifications: {
            // Show toasts at the top right of the screen
            position: "top-0 bottom-auto",
        },
        modal: {
            overlay: {
                background: "bg-slate-800/75",
            },
        },
        tabs: {
            list: {
                base: "relative",
                background: "bg-secondary !ring-0 !ring-transparent",
                rounded: "rounded-lg",
                shadow: "",
                padding: "p-1",
                height: "h-10",
                width: "w-full",
                marker: {
                    wrapper:
                        "absolute top-[4px] left-[4px] duration-200 ease-out focus:outline-none",
                    base: "w-full h-full",
                    background: "bg-first",
                    rounded: "rounded-md",
                    shadow: "",
                },
                tab: {
                    base: "relative inline-flex items-center justify-center",
                    active: "text-wht", // Couleur du texte de l'onglet actif
                    // inactive: "text-green-500", // Couleur du texte des onglets inactifs
                    hover: "hover:text-blue-600 dark:hover:text-blue-300", // Couleur au survol
                    selected: "font-medium",
                },
                slider: "bg-blue-500 dark:bg-blue-400", // Couleur de la barre de sélection
            },
        },
        avatar: {
            wrapper:
                "relative inline-flex items-center justify-center flex-shrink-0 [&>img]:object-cover [&>img]:w-full [&>img]:h-full",
        },
    },
});
