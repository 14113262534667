export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();

    // Ne charger AdSense qu'en production
    if (process.env.NODE_ENV === "production") {
        useHead({
            script: [
                {
                    src: `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9432141838767054`,
                    async: true,
                    crossorigin: "anonymous",
                },
            ],
        });
    }
});
