<template>
    <footer>
        <p class="p-6 text-wht">
            @ 2024 Football Games. -
            <a
                href="/privacy"
                target="_blank"
                class="cursor-pointer hover:underline"
            >
                Privacy
            </a>
            -
            <a
                href="/terms"
                target="_blank"
                class="cursor-pointer hover:underline"
            >
                Terms and Conditions
            </a>
            -
            <a
                href="/contact"
                target="_blank"
                class="cursor-pointer hover:underline"
            >
                Contact
            </a>
        </p>
    </footer>
</template>
